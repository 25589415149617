import React from 'react'
import {useIntl} from 'gatsby-plugin-intl'
import {Layout} from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Hero from '../components/heroBanner/heroBanner'
import style from '../assets/css/infoPage.module.css';

const TermsPage = (props) => {
  const intl = useIntl()

  return (
      <Layout>
        <Seo
            title={"Terms and Conditions"}
            url={props.location.pathname}
            lang={intl.formatMessage({id: 'MAIN_LANGUAGE'})}
            description={"General Terms and Conditions sam.events by SAM Entertainment"}
        />
        <Hero title="General Terms and Conditions sam.events by SAM Entertainment"/>
        <section className={`${style.infoSection2}`}>
          <h2>
            1 General
          </h2>
          <article>
            <p className={style.marginS}>
              These General Terms and Conditions ("Terms") outline the rights and obligations between SAM Entertainment
              and individuals or entities who:
            </p>

            <ul className={`${style.privacyList} ${style.marginXS}`}>
              <li>
                <p>(a) visit our Website; or</p>
              </li>
              <li>
                <p>(b) use any of our Services.</p>
              </li>
            </ul>
            <p className={style.marginS}>
              Please read this document carefully. By using the Website and/or the Services, you acknowledge that you
              have read and understood these Terms and agree to be bound by them, either on behalf of yourself or of the
              legal entity you represent. You may not use the Website or the Services if you do not agree with all of
              these Terms.
            </p>

            <p className={style.marginS}>
              By agreeing to these Terms, you warrant and represent:
            </p>
            <ul className={`${style.privacyList} ${style.marginXS}`}>
              <li>
                <p>(a) that you are at least 18 years old and capable of sound judgment or that you are at least 16
                  years old and your legal representative has consented to the use of the Services; and</p>
              </li>
              <li>
                <p>(b) that your use of the Website and the Services complies with all applicable laws and
                  regulations.</p>
              </li>
            </ul>

          </article>
        </section>
        <section className={`${style.infoSection2}`}>
          <h2>
            2 Definitions
          </h2>
          <article>
            <h3 className={style.marginS}>
              „Services“
            </h3>
            <p className={style.marginXS}>
              means the services set forth in paragraph 4, which shall be provided to the User by SAM Entertainment.
            </p>
          </article>
          <article>
            <h3 className={style.marginS}>
              „SAM Entertainment“
            </h3>
            <p className={style.marginXS}>
              shall mean SAM Entertainment AG, a private limited liability company incorporated and registered in
              Switzerland with company number CHE-235.420.059, whose registered office is at Hofstrasse 124, 8044
              Zürich, Switzerland.
            </p>
          </article>
          <article>
            <h3 className={style.marginS}>
              „Website“
            </h3>
            <p className={style.marginXS}>
              Shall mean www.samsaidyes.com, www.sam.events or any other website of SAM Entertainment including
              online-shops and mini sites for special offers, smart device application, mobile applications, presence on
              social media, multimedia portals, etc.
            </p>
          </article>
          <article>
            <h3 className={style.marginS}>
              „User“
            </h3>
            <p className={style.marginXS}>
              Shall mean any user creating an Account or using the services as a guest, whereas guest cannot be
              identified by SAM Entertainment.
            </p>
          </article>
          <article>
            <h3 className={style.marginS}>
              „Picture“
            </h3>
            <p className={style.marginXS}>
              Shall mean any picture, photograph, video, motion picture, whether digital origin or digitalized.
            </p>
          </article>
          <article>
            <h3 className={style.marginS}>
              „Administrator“
            </h3>
            <p className={style.marginXS}>
              Shall mean the responsible User for the Account who has the possibility, right and obligation to
              administer the Account (Event administrator).
            </p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>3 Agreement</h2>
          <article>
            <p className={style.marginS}>3.1 The User's access and use of the Services constitutes the User's agreement
              to be bound by these General Terms and Conditions, which establish a contractual relationship between the
              User and SAM Entertainment. If the User does not agree to these General Terms and Conditions, the Services
              of SAM Entertainment may not be used. These General Terms and Conditions expressly supersede any prior
              agreements or arrangements between the User and SAM Entertainment. SAM Entertainment may terminate these
              General Terms and Conditions or any services offered or generally cease offering or deny access to any of
              the services or parts thereof, at any time for any reason without giving reason or notice. However, SAM
              Entertainment will use reasonable commercial effort to provide the User with a notice thereof.</p>
            <p className={style.marginS}>3.2 Supplemental terms may apply to certain services. Such terms will be
              disclosed to the User in connection with the applicable services. Supplemental terms are in addition to,
              and shall be deemed a part of, these General Terms and Conditions for the purposes of the applicable
              services. In the event of a conflict with respect to the applicable services supplemental terms shall
              prevail over these General Terms and Conditions.</p>
            <p className={style.marginS}>3.3 SAM Entertainment may amend these General Terms and Conditions from time to
              time. Amendments will be effective upon notification if the User does not oppose by giving notice to SAM
              Entertainment within 30 days.</p>
            <p className={style.marginS}>3.4 SAM Entertainment collects and uses personal information in connection with
              the services as provided in the Data Protection Policy of SAM Entertainment which can be found at
              https://www.samsaidyes.com/privacy.</p>
            <p className={style.marginS}>3.5 Payments on the SAM Entertainment Website are not handled by SAM
              Entertainment but by a commissioned third party. Payments can be made by Credit card by entering the
              customer's credit card details on the Website or by any other payment method offered by the payment
              provider.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>4 Services</h2>
          <article>
            <p className={style.marginS}>4.1 SAM Entertainment provides the User with certain services (the "Services")
              through a technology platform that enables users of the Website provided (Users) as part of the services
              to upload and share Pictures. The Services shall include but are not limited to:</p>
            <ul className={`${style.privacyList} ${style.marginXS}`}>
              <li>
                <p>Create and administer albums;</p>
              </li>
              <li>
                <p>Share User Content;</p>
              </li>
              <li>
                <p>Share live-content;</p>
              </li>
              <li>
                <p>Invite other users to participate; and</p>
              </li>
              <li>
                <p>Feedback, questions and complaints relating to Services may be submitted to the following email
                  address: hello@samsaidyes.com. The User is encouraged to provide feedback.</p>
              </li>
            </ul>
            <p className={style.marginS}>4.2 The Services are made available solely for the User's personal,
              non-commercial use.</p>
            <p className={style.marginS}>4.3 The User expressly declares to agree to receiving transactional emails from
              SAM Entertainment, which are essential for carrying out the Services (e.g. sign-ups, receipts, etc.). In
              addition, new features, areas and all other Service-based communication will be informed via email. Users
              may unsubscribe from Service-based communication or any newsletter they have signed up for but not from
              trans-actional emails.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>5 Payment</h2>
          <article>
            <p className={style.marginS}>5.1 The fees that apply for the use of the Services are disclosed on the
              Website. Fees paid are final and non-refundable.</p>
            <p className={style.marginS}>5.2 If booking a Service, payment will be executed through a third party
              commissioned by SAM Entertainment on the Website. Upon request, SAM Entertainment will send a receipt to
              the User via email.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>6 User Account</h2>
          <article>
            <p className={style.marginS}>6.1 To use the Services, the User must register for and maintain an active user
              Services account (“Account”) by following the instruction given on the Website. The User must be at least
              18 years of age to create an Account. Account registration may require the User to submit certain personal
              information to SAM Entertainment, such as name, address, age, gender, email, additional information etc.
              The User agrees to maintain accurate, complete, and up-to-date information in its Account. The User's
              failure to maintain accurate, complete, and up-to-date Account information may result in his inability to
              access and use the Services. The User is responsible for all activity that occurs under its Account, and
              the User agrees to maintain the security and secrecy of the Account username and password at all
              times.</p>
            <p className={style.marginS}>6.2 Any User may only have one Account and may not authorize third parties to
              use its Account. The Account cannot be assigned or otherwise transferred to any other person.</p>
            <p className={style.marginS}>6.3 The User may be asked to provide proof of identity and age verification to
              access or use the Services, and the User agrees that access to the use of the Services may be denied if
              the User refuses to provide the needed information.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>7 User Content</h2>
          <article>
            <p className={style.marginS}>7.1 The User may submit, upload, publish or otherwise make available to SAM
              Entertainment Pictures, textual, audio, and/or visual content and information, initiation of support
              requests, etc. (“User Content”). Any User Content provided remains the User's property and the User may
              decide at all times when and how to share and / or transfer the User Content. The User expressly
              acknowledges, that any User Content is shared within the defined Group of Users by the Administrator. For
              the Website to work, it may need access to the User's camera and album, however, SAM Entertainment will
              always respect the User's privacy and will not use or publish any User Content without the User's prior
              Consent. If the user consents to publishing User Content by SAM Entertainment, the User represents and
              warrants that he is the sole owner of all User Content or has all rights, licenses, consents and releases
              necessary to grant SAM Entertainment the license to the User Content as set out hereinabove as and if
              needed.</p>
            <p className={style.marginS}>7.2 The User agrees to share any User Content uploaded with other Users as
              defined by the Administrator and to grant the Administer (not SAM Entertainment) a worldwide, perpetual,
              irrevocable, transferable, royalty-free license, with the right to sublicense, to use, copy, modify,
              create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any
              manner such User Content in all formats and distribution channels now known or hereafter devised
              (including in connection with the Services and on third-party sites and services), without further notice
              to or consent from the User, and without the requirement of payment to the User or any other person or
              entity.</p>
            <p className={style.marginS}>7.3 The User's commentary and feedback related to the Services may be
              considered, implemented, disclosed, published or used in any way whatsoever by SAM Entertainment without
              the User's consent, without further notice and without the requirement of payment to the User or any other
              person or entity.</p>
            <p className={style.marginS}>7.4 The User represents and warrants that he owns all rights to the User
              Content, including, without limitation, the picture or digital recording or the performance contained,
              that the User is the individual pictured and heard or, alternatively, that the User has obtained
              permission from each person who appears in the User Content to grant the rights as described herein, that
              the User Content has been legally obtained and created, that the User Content does not infringe any
              intellectual property rights of any third party and that the User Content does not violate applicable laws
              or regulations. The User agrees to not provide User Content that is defamatory, libelous, hateful,
              violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by SAM Entertainment in
              its sole discretion, whether or not such material may be protected by law. SAM Entertainment may, but
              shall not be obligated to, review, monitor, or remove User Content at any time and for any reason, without
              notice to the User.</p>
            <p className={style.marginS}>7.5 SAM Entertainment does not provide any User Content itself. The User
              Content and any derivative work or expectations are in the sole discretion of the User and under the
              User's full liability.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>8 Restrictions</h2>
          <article>
            <p className={style.marginS}>8.1 The User may not: (i) remove any copyright, trademark or other proprietary
              notices from any portion of the Services; (ii) reproduce, modify, prepare derivative works based upon,
              distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream,
              broadcast or otherwise exploit the Services except as expressly permitted by SAM Entertainment; (iii)
              decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law; (iv)
              link to, mirror or frame any portion of the Services; (v) cause or launch any programs or scripts for the
              purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly
              burdening or hindering the operation and/or functionality of any aspect of the Services; or (vi) attempt
              to gain unauthorized access to or impair any aspect of the Services or its related systems or
              networks.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>9 Limitations of liability</h2>
          <article>
            <p className={style.marginS}>9.1 To the extent permitted by the applicable law, SAM Entertainment shall not
              be liable to the User in any way (whether by contract (by way of indemnity or otherwise) or tort
              (including negligence) misrepresentation, breach of statutory duty, restitution or otherwise) for any
              Service provided through the Website, for:</p>
            <ul className={`${style.privacyList} ${style.marginXS}`}>
              <li>
                <p>Any expectation of the Services of the User;</p>
              </li>
              <li>
                <p>Quality or quantity of User Content;</p>
              </li>
              <li>
                <p>any losses that could not reasonably be expected to result from SAM Entertainment's negligence or
                  breach of these Terms and Conditions;</p>
              </li>
              <li>
                <p>any loss, damage, costs, expenses or liability that the User suffers in connection with the
                  Services.</p>
              </li>
              <li>
                <p> If SAM Entertainment becomes liable to the User in connection with the services, its liability will
                  be limited to the extent possible by law.</p>
              </li>
            </ul>
            <p className={style.marginS}>9.2 The Services are provided "as is" and "as available". SAM Entertainment
              disclaims all representations and warranties, express, implied or statutory. SAM Entertainment makes no
              representation, warranty or guarantee regarding reliability, quality or availability of the Services.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>10 Indemnity</h2>
          <article>
            <p className={style.marginS}>10.1 The User agrees to indemnify and hold SAM Entertainment and its officers,
              directors, employees, and agents harmless from any and all claims, demands, losses, liabilities, and
              expenses (including attorneys’ fees) arising out of or in connection with: (i) the User's use of the
              Services or services or goods obtained through the User's use of the Services; (ii) the User's breach or
              violation of any of these Terms; (iii) SAM Entertainment's use of the User Content; or (iv) the User's
              violation of the rights of any third party, including third-party providers.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>11 Severability Clause</h2>
          <article>
            <p className={style.marginS}>11.1 If any provision of these Terms is held to be illegal, invalid or
              unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be
              deemed not to form part of these Terms but the legality, validity and enforceability of the other
              provisions in these Terms shall not be affected. In that event, the parties shall replace the illegal,
              invalid or unenforceable provision or part thereof with a provision or part thereof that is legal, valid
              and enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid or
              unenforceable provision or part thereof, given the contents and purpose of these Terms.</p>
          </article>
        </section>

        <section className={`${style.infoSection}`}>
          <h2 className={style.marginS}>12 Applicable Law and Jurisdiction</h2>
          <article>
            <p className={style.marginS}>12.1 The services and these Terms and all non-contractual obligations arising
              in any way whatsoever out of or in connection with the services shall be governed by, construed and take
              effect in accordance with the laws of Switzerland.</p>
            <p className={style.marginS}>12.2 Any dispute, claim or matter of difference arising out of or relating to
              the services or these Terms shall be subject to the exclusive jurisdiction of the competent courts of
              Zurich, Switzerland.</p>
          </article>
        </section>
        <section className={`${style.infoSection}`}>
          <article>
            <p className={style.marginS}>Please see also our <a href="/privacy">Privacy Policy</a></p>
          </article>
        </section>
      </Layout>
  )
}

export default TermsPage
